import axios from 'axios';
import React, { useState } from 'react';
import WhisperForm from './WhisperForm';

interface MassMessageValidationPageProps {
    authToken: string;
}

type MassMessage = {
    id: number;
    message: string;
    price_in_cents: number;
    preview_media_count: number;
    locked_media_count: number;
    validation_status: string;
    timestamp: string;
};

const MassMessageValidationPage: React.FC<MassMessageValidationPageProps> = ({ authToken }) => {
    const [modelId, setModelId] = useState<string>('');
    const [massMessages, setMassMessages] = useState<MassMessage[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const fetchMassMessages = async (modelID: string) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/restricted/models/` + modelID + `/massmessages`, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            setMassMessages(response.data);
        } catch (error) {
            setError("An error occurred while fetching model data.");
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModelId(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        try {
            await fetchMassMessages(modelId);
        } catch (error) {
            console.error('Failed to fetch friends:', error);
            // Handle errors as needed
        } finally {
            setLoading(false);
        }
    };

    const updateMessageStatus = (id: number, newStatus: string) => {
        setMassMessages(massMessages.map(msg =>
            msg.id === id ? { ...msg, validation_status: newStatus } : msg
        ));
    };

    const invalidateMassMessage = (id: number) => {
        // Make an API call to invalidate the message with the given id
        // Replace with your actual endpoint and request logic
        axios.post(`/api/restricted/models/${modelId}/massmessages/${id}/invalidate`, {}, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }).then(response => {
            updateMessageStatus(id, 'NOT_VALID');
        }).catch(error => {
            console.error('Error invalidating:', error);
        });
    }

    const validateMassMessage = (id: number) => {
        // Make an API call to validate the message with the given id
        // Replace with your actual endpoint and request logic
        axios.post(`/api/restricted/models/${modelId}/massmessages/${id}/validate`, {}, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }).then(response => {
            updateMessageStatus(id, 'VALID');
        }).catch(error => {
            console.error('Error validating:', error);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label htmlFor="modelID">Enter ID:</label>
                <input
                    type="text"
                    id="modelID"
                    value={modelId}
                    onChange={handleInputChange}
                />
                <button type="submit" disabled={loading}>
                    {loading ? 'Loading...' : 'Get Mass Messages'}
                </button>
            </form>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                massMessages.length > 0 && (
                    <div>
                        <h2>Mass Messages List:</h2>
                        <ul>
                            {massMessages.map((massMessage) => (
                                <li key={massMessage.id}>
                                    <p>Message: {massMessage.message}</p>
                                    <p>Price: ${massMessage.price_in_cents.toFixed(2)}</p>
                                    <p>Preview Media Count: {massMessage.preview_media_count}</p>
                                    <p>Locked Media Count: {massMessage.locked_media_count}</p>
                                    <p>Timestamp: {massMessage.timestamp}</p>
                                    <p>Validation Status: {massMessage.validation_status}</p>
                                    {massMessage.validation_status === 'VALID' ? (
                                        <button
                                            style={{ backgroundColor: 'red', color: 'white' }}
                                            onClick={() => invalidateMassMessage(massMessage.id)}
                                        >
                                            Invalidate
                                        </button>
                                    ) : null}
                                    {massMessage.validation_status !== 'VALID' ? (
                                        <button
                                            style={{ backgroundColor: 'green', color: 'white' }}
                                            onClick={() => validateMassMessage(massMessage.id)}
                                        >
                                            Validate
                                        </button>
                                    ) : null}
                                </li>
                            ))}
                        </ul>
                    </div>
                )
            )}
        </div>
    );
};

export default MassMessageValidationPage;
