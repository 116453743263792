import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModelInfoRow, { ModelInfo } from './ModelInfoRow';

interface ModelPageProps {
    modelID: number;
    authToken: string;
}

const ModelPage: React.FC<ModelPageProps> = ({ modelID, authToken }) => {
    const [modelInfo, setModelInfo] = useState<ModelInfo | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await axios.get(`/api/restricted/models/${modelID}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setModelInfo(response.data);
            } catch (error) {
                setError("An error occurred while fetching model data.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [modelID, authToken]);

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : modelInfo ? (
                <ModelInfoRow modelInfo={modelInfo} authToken={authToken} isAdmin={false} />
            ) : (
                <p>No data</p>
            )}
        </div>
    );
};

export default ModelPage