import React, { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';

interface Field {
    name: string;
    type: string;
    placeholder: string;
}

interface WhisperFormProps {
    fields: Field[];
    submitUrl: string;
    buttonText: string;
    onSuccess?: () => void;
    onError?: () => void;
    handleResponse?: (response: any) => void; // The new callback
    token?: string; // Optional token for authenticated requests
}

const WhisperForm: React.FC<WhisperFormProps> = ({
    fields,
    submitUrl,
    buttonText,
    onSuccess,
    onError,
    handleResponse, // The new callback
    token,
}) => {
    const initialFormData: Record<string, string> = {};
    fields.forEach((field) => (initialFormData[field.name] = ''));

    const [formData, setFormData] = useState<Record<string, string>>(initialFormData);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsLoading(true);

        const config: { headers: { [key: string]: string } } = {
            headers: {},
        };

        // If a token exists, include it in the Authorization header
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        try {
            const response = await axios.post(submitUrl, formData, config);
            if (response.status === 200) {
                setIsSuccessful(true);
                if (onSuccess) onSuccess();
                if (handleResponse) handleResponse(response); // Call the new callback
            } else {
                if (onError) onError();
            }
        } catch (error) {
            console.error('Error:', error);
            if (onError) onError();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {fields.map(field => (
                <input
                    key={field.name}
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={formData[field.name] || ''}
                    onChange={handleChange}
                />
            ))}
            <button disabled={isLoading || isSuccessful}>
                {isLoading ? 'Sending request...' : (isSuccessful ? 'Submitted' : buttonText)}
            </button>
        </form>
    );
};

export default WhisperForm;
