import React, { useState } from 'react';
import MassMessageValidationPage from './MassMessageValidationPage';
import ModelTable from './ModelTable';
import WhisperForm from './WhisperForm';

interface AdminPageProps {
    authToken: string;
}

const AdminPage: React.FC<AdminPageProps> = ({ authToken }) => {
    const [selectedTab, setSelectedTab] = useState<string | null>('signup'); // Default tab

    const tabs = [
        { id: 'signup', label: 'Sign Up' },
        { id: 'models', label: 'Models' },
        { id: 'massmessage', label: 'Mass Message Validation' }
        // Add more tabs here
    ];

    const signUpFields = [
        { name: 'name', type: 'text', placeholder: 'Name' },
        { name: 'email', type: 'email', placeholder: 'Email' },
        { name: 'password', type: 'password', placeholder: 'Password' },
        { name: 'gauthKey', type: 'text', placeholder: 'Gauth Key' }
    ];

    return (
        <div>
            <div className="tabs">
                {tabs.map((tab) => (
                    <button key={tab.id} onClick={() => setSelectedTab(tab.id)}>
                        {tab.label}
                    </button>
                ))}
            </div>

            <div className="tab-content">
                {tabs.map((tab) => (
                    selectedTab === tab.id && (
                        <div key={tab.id}>
                            {tab.id === 'signup' && (
                                <>
                                    <h1>{"On-board"}</h1>
                                    <WhisperForm
                                        fields={signUpFields}
                                        token={authToken}
                                        submitUrl={"/api/restricted/workflow/onboard"}
                                        buttonText="On-board"
                                        onSuccess={() => console.log("On-board successful!")}
                                        onError={() => console.error("On-board failed!")}
                                    />
                                </>
                            )}
                            {tab.id === 'models' && <ModelTable authToken={authToken} />}
                            {tab.id === 'massmessage' && <MassMessageValidationPage authToken={authToken} />}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default AdminPage;
