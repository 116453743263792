import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ModelInfoRow, { ModelInfo } from './ModelInfoRow';

interface ModelTableProps {
    authToken: string
}

const ModelTable: React.FC<ModelTableProps> = ({ authToken }) => {
    const [modelTableInfo, setModelTableInfo] = useState<ModelInfo[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await axios.get(`/api/restricted/models`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setModelTableInfo(response.data);
            } catch (error) {
                setError("An error occurred while fetching model data.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [authToken]);
    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : modelTableInfo ? (
                <div>
                    {modelTableInfo.map((modelInfo, index) => (
                        <ModelInfoRow key={index} modelInfo={modelInfo} authToken={authToken} isAdmin={true} />
                    ))}
                </div>
            ) : (
                <p>No data</p>
            )}
        </div>
    );
};

export default ModelTable;
