import React, { useState } from 'react';
import WhisperForm from './WhisperForm';

interface LoginPageProps {
    submitUrl: string;
    title: string;
    onSuccess: (token: string, modelID?: number | null) => JSX.Element;
}

const LoginPage: React.FC<LoginPageProps> = ({ submitUrl, title, onSuccess }) => {
    const [modelID, setModelID] = useState<number | null>(null);
    const [token, setToken] = useState<string | null>(null);

    const loginFields = [
        { name: 'email', type: 'email', placeholder: 'Email' },
        { name: 'password', type: 'password', placeholder: 'Password' },
    ];

    const handleResponse = (response: any) => {
        const { token, model_id } = response.data;
        setModelID(model_id);
        setToken(token);
    };

    return (
        <div>
            {token ? (
                onSuccess(token, modelID)
            ) : (
                <>
                    <h1>{title}</h1>
                    <WhisperForm
                        fields={loginFields}
                        submitUrl={submitUrl}
                        buttonText="Log In"
                        handleResponse={handleResponse}
                    />
                </>
            )}
        </div>
    );
};

export default LoginPage;
