import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ModelPage from './components/ModelPage';
import LoginPage from './components/LoginPage';
import AdminPage from './components/AdminPage';


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/admin" element={<LoginPage submitUrl="/api/admin/login" title="Admin Login" onSuccess={(token) => <AdminPage authToken={token} />} />} />
        <Route path="/" element={<LoginPage submitUrl="/api/client/login" title="Model Login" onSuccess={(token, modelID) => <ModelPage modelID={modelID!} authToken={token} />} />} />
      </Routes>
    </Router >
  );
};



export default App;