import React, { useState } from 'react';
import axios from 'axios';

export interface ModelInfo {
    id: number;
    name: string;
    email: string;
    external_id: string;
    active: boolean;
    running: boolean;
}

interface ModelInfoRowProps {
    modelInfo: ModelInfo;
    authToken: string;
    isAdmin: boolean;
}


const ModelInfoRow: React.FC<ModelInfoRowProps> = ({ modelInfo, authToken, isAdmin }) => {
    const [isRunning, setIsRunning] = useState<boolean>(modelInfo.running);
    const [statusMessage, setStatusMessage] = useState<string>('');
    const [isFlowBuilderStarted, setIsFlowBuilderStarted] = useState(false);

    const handleStopClick = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/stop`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Stopped the model:', response.data);
            setIsRunning(false);
            setStatusMessage('Model successfully stopped');
        } catch (error) {
            console.error('An error occurred while stopping the model:', error);
        }
    };

    const handleStartClick = async () => {
        var url = `/api/restricted/models/${modelInfo.id}/startChatter`
        try {
            const response = await axios({
                method: 'post',
                url: url,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started the model:', response.data);
            setIsRunning(true);
            setStatusMessage('Model successfully started');
        } catch (error) {
            console.error('An error occurred while starting the model:', error);
        }
    };

    const handleFlowBuilderStart = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `/api/restricted/models/${modelInfo.id}/buildFlows`,
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });
            console.log('Started flow builder:', response.data);
            setIsFlowBuilderStarted(true);
        } catch (error) {
            console.error('An error occurred while starting flow builder:', error);
        }
    };

    return (
        <div>
            <h1>{modelInfo.name}</h1>
            <p>Email: {modelInfo.email}</p>
            <p>External ID: {modelInfo.external_id}</p>
            <p>Active: {modelInfo.active ? 'Yes' : 'No'}</p>
            <div>
                <p style={{ display: 'inline-block' }}>Running: {isRunning ? 'Yes' : 'No'}</p>
                {isRunning && (
                    <button
                        style={{ backgroundColor: 'red', marginLeft: '20px' }}
                        onClick={handleStopClick}
                    >
                        Stop
                    </button>
                )}
                {modelInfo.active && !isRunning && (
                    <button
                        style={{ backgroundColor: 'green', marginLeft: '20px' }}
                        onClick={() => handleStartClick()}
                    >
                        Start
                    </button>
                )}
                <p>{statusMessage}</p>
            </div>
            <div>
                {isAdmin && !isFlowBuilderStarted ? (
                    <button
                        style={{ backgroundColor: 'blue', color: 'white', marginTop: '20px' }}
                        onClick={handleFlowBuilderStart}
                    >
                        Start Flow Builder
                    </button>
                ) : (
                    <p>Flow builder started</p>
                )}
            </div>
        </div>
    );
};

export default ModelInfoRow;
